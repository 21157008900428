<!-- 退款审核 -->
<template>
  <div class="refundaudit1">
    <div class="step" v-if='audittype==0'>
      <svg class='svg'><use xlink:href="#iconyixiadan1"></use></svg>您的退货退款申请已提交，请耐心等待客服人员处理。
    </div>
    <div class="step step1 step3" v-if='audittype==3'>
      <p class="pm"><span class="icon"><svg><use xlink:href="#iconwancheng"></use></svg></span>您的退货申请已通过。</p>
      <p class="pm1">请尽快将商品邮寄至指定地址，并填写订单物流信息。</p>
      <p class="pm2">售后人员收到退货物品<span>5个工作日内</span>安排退款，退款金额将原路退回原支付账号。</p>
    </div>
    <div class="step step1 step2" v-if='audittype==2'>
      <p class="pm"><span class="icon"><svg><use xlink:href="#iconshanchu"></use></svg></span>抱歉，您的退款申请审核未通过。</p>
      <!-- {{productList&&productList.RefundApplyModel.AuditDescT?productList.RefundApplyModel.AuditDescT:productList.RefundApplyModel.AuditDesc}} -->
      <p class="pm1">审核未通过的原因：<span>此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后此处原因为客服后</span>（此处原因为客服后台拒绝原因）</p>
    </div>
    <p v-if='audittype!=3' class="p">如有疑问，请联系<a href="javascript:;" @click='lianxikefu'>在线客服>></a></p>
    <p v-if='audittype==3' class="p p1">【退货地址及收货人信息】请联系<a href="javascript:;" @click='lianxikefu'>在线客服>></a></p>
    <button  v-if='audittype==3' @click='writeAddress' class="setaddr">去填写物流信息</button>
  </div>
</template>
<script>
export default {
  name: 'refundaudit1',
  data(){
    return {
    }
  },
  props: {
    audittype: Number,//审核步骤
    productList: Object,//申请数据
  },
  methods:{
    writeAddress(){
      // 填写物流
      this.$emit('exportWrite')
    },
    lianxikefu(){
      // 联系客服
      _MEIQIA('showPanel');
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.refundaudit1 {
  .step {
    width:690px;padding-bottom:20px;border-radius: 10px;background: #fafafa;font-size: 20px;color:#333;padding-left:50px;line-height: 100px;font-weight: bold;margin-top:40px;
    svg {
      width: 35px;height: 35px;vertical-align: -11px;margin-right: 10px;
    }
    .svg {
      fill: #3f86f2;
    }
  }
  .step1 {
    .pm {
      line-height: 70px;font-size: 20px;
    }
    .pm1 {
      line-height: 20px;font-weight: normal;color:#999999;font-size: 14px;padding-left:46px;max-width: 600px;
      span {
        color:#2c80db;
      }
    }
    .icon {
       width: 30px;height: 30px;display: inline-block;background: #4ed45b;line-height: 35px;margin-right: 15px;text-align: center;border-radius: 50%;
      svg {
        width: 20px;height: 20px;fill: #fff;margin-right: 0px;vertical-align: -3px;
      }
    }
  }
  .step2 {
    .pm1 {
      span {
        color:#f96469;
      }
    }
    .icon {
      background: #f96464;
      svg {
        width: 15px;height: 15px;margin-right: 0px;vertical-align: 0px;
      }
    }
  }
  .step3 {
    height: 130px;
    .pm2 {
      line-height: 60px;font-weight: normal;color:#333;font-size: 14px;padding-left:46px;
      span {
        color:#2c80db;
      }
    }
  }
  .p {
    padding-left:80px;height: 55px;line-height: 55px;
    a {
      color:#2c80db;
      &:hover {
        opacity:0.8;
      }
    }
  }
  .p1 {
    padding-left:90px;
  }
  .setaddr {
    width: 148px;height: 42px;margin-left:95px;border-radius: 5px;background: #f54e67;font-size: 14px;color:#fff;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
