<!-- 退货地址 -->
<template>
  <div class="refundaddr">
    <div v-if='types==0' class="types">
      <h6 class="h6">请退货并填写物流信息</h6>
      <div class="info">
        <h5 class="h">1.请退货</h5>
        <p class="p">请联系在线客服获取退货信息，请勿使用到付或平邮等方式，否则将会影响您的退款进程。</p>
        <p class="p1">如有任何疑问，请联系<a href="javascript:;" @click='lianxikefu'>在线客服>></a></p>
      </div>
      <div class="info">
        <h5 class="h">2.请填写退货物流信息（逾期未填写，退货申请将关闭）</h5>
      </div>
      <div class="group-table first">
        <div class="lt">
          物流公司：
          <span>*</span>
        </div>
        <div class="rt">
          <!-- <div class="selectBox">
            <el-select v-model="value" placeholder="请选择物流公司" >
              <el-option
                v-for="item,index in options"
                :key="index"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <div class="rePrice">
            <input type="text"  v-model='CourierName'>
          </div>
          <span class="error" v-if='error.CourierName'>{{error.CourierName}}</span>
        </div>
      </div>
      <div class="group-table">
        <div class="lt">
          运单号码：
          <span>*</span>
        </div>
        <div class="rt">
          <div class="rePrice">
            <input type="text"  v-model='numberNums'>
          </div>
          <span class="error" v-if='error.numberNums'>{{error.numberNums}}</span>
        </div>
      </div>
      <div class="group-table">
        <div class="lt">
          退款说明：
          <p>(<i>{{textareas?textareas.length:0}}</i>/200字)</p>
        </div>
        <div class="rt">
          <div class="textareaBox">
            <textarea placeholder="退款说明" v-model='textareas' maxlength="200"></textarea>
          </div>
        </div>
      </div>
      <div class="group-table">
        <div class="lt">
          上传凭证：
        </div>
        <div class="rt">
          <div class="uploadimg">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              :multiple='true'
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :before-upload='upfn'
              :http-request='selfupload'
              accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF,"
              >
              <span class="iconss"><svg><use xlink:href="#iconxiangji"></use></svg></span>
              <p>电脑传图</p>
            </el-upload>
            <div class="imgnum">共<span>{{uploadImg}}</span>张，还能上传<span>{{maxUploadImg-uploadImg}}</span>张</div>
            <!-- <div class="imginfo">请提供实物图片(包含条形码)，以便您的退款或退货申请顺利通过审核</div> -->
          </div>
        </div>
      </div>
      <button class="uploadInfo" :disabled="btmpas" @click='postProduct'>提交退货信息</button>
    </div>
    <div v-if='types==1' class="types1">
      <div class="hd">
        <h6><span><svg><use xlink:href="#iconwancheng"></use></svg></span>您的退货信息提交成功</h6>
        <p class="p">物流可能需要3-5个工作日，请耐心等待</p>
        <p class="p1">售后人员收到退货物品<span>5个工作日内</span>安排退款，退款金额将原路退回原支付账号。</p>
      </div>
      <p class="p2">如有疑问，请联系<a href="javascript:;" @click='lianxikefu'>在线客服>></a></p>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'refundaddr',
  props: {
    types: {
      default:0,
      type:Number,
    },
    productList:Object
  },
  data(){
    return {
      // options: [{
      //   value: '0',
      //   label: '我不想要了'
      // }, {
      //   value: '1',
      //   label: '质量问题'
      // }, {
      //   value: '2',
      //   label: '发错货了'
      // }, {
      //   value: '3',
      //   label: '协商一致退货退款'
      // }],
      // value: '',
      btmpas:false,
      CourierName:'',
      numberNums:'',
      textareas:'',
      uploadImg:0,//当前上传图片数量
      maxUploadImg:3,//上传图片上限
      dialogImageUrl: '',//预览图片地址
      dialogVisible: false,//显示隐藏预览弹窗
      rmkObj:{
        uploaddata:[],
      },//提交退款对象
      error:{
        CourierName:'',
        numberNums:'',
      }
    }
  },
  methods:{
    lianxikefu(){
      // 联系客服
      _MEIQIA('showPanel');
    },
    postProduct(){
      // 提交退款
      this.error.CourierName=''
      this.error.numberNums=''
      var _flag = true;
      if(!this.CourierName){
        _flag = false
        this.error.CourierName='请填写物流公司'
      }
      if(!this.numberNums){
        _flag = false
        this.error.numberNums='请填写运单号码'
      }
      if(_flag){
        var _parms = {
          // CustomerId:this.$store.state.user&&this.$store.state.user.CustomerID?this.$store.state.user.CustomerID:'',
          token:this._token({method:'get'}),
          RefundApplyId:this.productList.RefundApplyModel.Id,
          CourierName:this.CourierName,
          CourierNumber:this.numberNums,
          Reamrk:this.textareas
        }
        for(let i in this.rmkObj.uploaddata){
          _parms['file'+i] = this.rmkObj.uploaddata[i]
        }
        // 保存退款信息
        this.btmpas = true;//请求接口禁用完成前调用一次
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/SaveRefundGoods',this.keys(_parms))
            .then(res => {
              if(res.data.Success){
                this.btmpas = false//请求接口禁用完成前调用一次
                this.$emit('exportWl')
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              console.log('系统异常')
            })
        },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    handlePictureCardPreview(file) {
      // 预览上传图片
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      // 删除上传图片
      this.uploadImg = fileList.length
      this.rmkObj.uploaddata = [];
      for(let i in fileList){
        this.rmkObj.uploaddata.push(fileList[i].raw)
      }
      this.$emit('expupload',this.rmkObj)
    },
    upfn(file){
      // 上传前计数
      this.uploadImg++
      if(this.uploadImg>this.maxUploadImg){
        this.uploadImg=this.maxUploadImg
        this.$MessageBox.alert('最多上传3张图片',{type:'error'});
        return false
      }
      // return;
    },
    selfupload(file){
      // 自定义上传
      this.rmkObj.uploaddata.push(file.file)
      this.$emit('expupload',this.rmkObj)
    },
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.refundaddr {
  .types {
    .uploadInfo {
      width: 250px;height: 47px;border-radius: 5px;background: #f04565;color:#fff;font-size: 16px;font-weight: bold;margin-left:180px;
      &:hover {
        opacity: 0.8;
      }
    }
    .h6 {
      font-size: 24px;margin-top:20px;color:#353535;
    }
    .info {
      .h {
        font-size: 16px;margin-top:20px;margin-bottom:10px;
      }
      .p {
        color:#9fa19f;font-size: 14px;margin-bottom:10px;
      }
      .p1 {
        font-size: 14px;margin-bottom:30px;
        a {
          color:#4780db;
        }
      }
    }
  }
  .types1 {
    margin-top:50px;
    .hd {
      background: #fafafa;border-radius: 5px;padding-bottom:25px;
      h6 {
        font-size: 20px;padding-top:30px;margin-bottom:15px;padding-left:40px;display: flex;align-items: center;
        span {
          width: 30px;height: 30px;background: #4ed45b;display: inline-block;border-radius: 50%;text-align: center;display: flex;justify-content: center;align-items: center;margin-right: 20px;
          svg {
            fill: #fff;width: 20px;height: 20px;
          }
        }
      }
      .p {
        padding-left:90px;font-size: 14px;color:#9fa19f;margin-bottom:10px;
      }
      .p1 {
        padding-left:90px;font-size: 14px;
        span {
          color:#4c84e1;
        }
      }
    }
    .p2 {
      padding-left:90px;margin-top:30px;
      a {
        color:#4c84e1;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .group-table {
    display: block;zoom:1;margin-bottom:40px;
    .lt {
      float:left;width: 80px;color:#0f0f0f;font-size: 14px;position: relative;margin-left:100px;
      span {
        color:#ff4e0b;position: absolute;left:-10px;top:3px;
      }
      p {
        font-size: 12px;color:#ccc;
      }
      i {
        font-style: normal;font-size: 12px;
      }
    }
    .rt {
      float:right;width: 560px;display: flex;justify-content: left;align-items: center;position: relative;
      .img {
        width: 84px;height: 84px;border:1px solid #f4f4f4;
        img {
          display: block;width: 84px;height: 84px;
        }
      }
      .info {
        margin-left:20px;
        h6 {
          font-size: 14px;color:#0f0f0f;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;
        }
        p {
          font-size: 12px;color:#9f9f9f;
        }
      }
      .msg {
        font-size: 14px;color:#0f0f0f;
      }
      .radioBox {
        margin-top:0px;
      }
      .selectBox {
        margin-top:-9px;
      }
      .rePrice {
        position: relative;margin-top:-9px;
        span {
          position: absolute;font-size: 16px;color:#ff4e0b;font-size: 16px;top:8px;left: 12px;
        }
        input {
          border:1px solid #DCDFE6;width: 196px;height: 38px;border-radius: 3px;color:#797979;padding-left:18px;font-size: 16px;
        }
        p {
          color:#a39da1;margin-top:10px;font-size: 12px;
        }
      }
      .textareaBox {
        margin-top:-8px;
        textarea {
          border:1px solid #DCDFE6;width: 440px;height: 125px;padding:10px;font-size: 14px;border-radius: 5px;
          &::placeholder {
            color:#ccc;font-size: 14px;
          }
        }
      }
      .iptBox {
        margin-top:-9px;
        p {
          color:#a39da1;font-size: 12px;margin-top:10px;
        }
      }
      .uploadImg {
        width: 460px;
      }
      .uploadMenu {
        button {
          width: 200px;height: 40px;border-radius: 5px;background: #f04565;color:#fff;font-size: 14px;font-weight: bold;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .uploadimg {
        /deep/.el-upload {
          width: 50px;height: 50px;line-height: 8px;
          p {
            font-size: 12px;transform: scale(0.8);margin-top:3px;
          }
        }
        /deep/.el-upload-list__item {width: 50px;height: 50px;line-height: 50px;margin-bottom:0px;}
        /deep/.iconss {
          vertical-align: -4px;
          svg {
            width: 30px;height: 30px;fill: #ccc;
          }
        }
        .imgnum {
          font-size: 12px;color:#999999;margin-top:5px;
          span {
            color:#e4393c;font-size: 12px;margin:0 2px;
          }
        }
        .imginfo {
          font-size: 14px;color:#797979;margin-top:5px;
        }
      }
      .error {
        position: absolute;top:33px;font-size: 12px;color:red;
      }
    }
    .rt1 {
      align-items: flex-start;
    }
    &:after {
      content: "";display: block;clear:both;
    }
  }
  .first {
    margin-top:40px;
  }
}
</style>
